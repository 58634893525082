import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Hiring from "../../images/it-staff/Hiring.svg"
import * as styles from "./HiringProcess.module.scss"

const HiringProcess = ({ strapiData }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={styles.banner}>
      <Container>
        <div>
          <div className={styles.heading}>
            <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />
          </div>
          <div lg={5} md={12} className="text-center mt-5 mt-md-0">
            {image ? (
              <GatsbyImage
                placeholder="blurred"
                decoding="async"
                loading="lazy"
                image={image}
                alt={strapiData?.secImages[0]?.alternativeText}
              />
            ) : (
              <img
                src={Hiring}
                alt={strapiData?.secImages[0]?.alternativeText}
                decoding="async"
                loading="lazy"
                className={styles.enforceImg}
              />
            )}
          </div>
          <Row>
            {strapiData?.cards?.map(e => (
              <Col lg={{ span: 1, offset: 1 }} key={e?.title}>
                <div className={styles.content}>
                  <img
                    className={styles.enforceImg2}
                    decoding="async"
                    loading="lazy"
                    alt={strapiData?.secImages[0]?.alternativeText}
                    src={strapiData?.secImages[0]?.localFile?.publicURL}
                  />
                  <h3>{e?.title}</h3>
                </div>
              </Col>
            ))}
          </Row>
          <div className="pt-4 text-center">
            {strapiData?.buttons[0] && (
              <Link
                to={strapiData?.buttons[0]?.url}
                className={styles.btn_black_banner_cta_contact}
              >
                {strapiData?.buttons[0]?.title}
              </Link>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default HiringProcess
