// extracted by mini-css-extract-plugin
export var banner = "Banner-module--banner--e184d";
export var bannerBtn = "Banner-module--bannerBtn--e52d0";
export var bannerImg = "Banner-module--bannerImg--87357";
export var bannerUl = "Banner-module--bannerUl--d75fb";
export var btn_black_border = "Banner-module--btn_black_border--1e8b9";
export var heading = "Banner-module--heading--2f8ef";
export var itStaffBreadCrumbs = "Banner-module--itStaffBreadCrumbs--e688f";
export var liBullet = "Banner-module--liBullet--5370f";
export var mainHeading = "Banner-module--mainHeading--ab28a";
export var subHeading = "Banner-module--subHeading--77c30";