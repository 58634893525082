// extracted by mini-css-extract-plugin
export var Group = "FieldProven-module--Group--01ee4";
export var Plus = "FieldProven-module--Plus--093ce";
export var Vector = "FieldProven-module--Vector--98dd7";
export var arrowImg = "FieldProven-module--arrowImg--5336f";
export var card = "FieldProven-module--card--782a1";
export var cardWrapper = "FieldProven-module--cardWrapper--bed65";
export var choseImg = "FieldProven-module--choseImg--d7193";
export var cir = "FieldProven-module--cir--c5127";
export var description = "FieldProven-module--description--39b95";
export var dots1 = "FieldProven-module--dots1--77251";
export var dots2 = "FieldProven-module--dots2--532e7";
export var dots3 = "FieldProven-module--dots3--ceef6";
export var dots4 = "FieldProven-module--dots4--02f10";
export var for1 = "FieldProven-module--for1--13992";
export var heading = "FieldProven-module--heading--6feef";
export var staffRec = "FieldProven-module--staffRec--387d2";
export var subservice = "FieldProven-module--subservice--a8ddd";
export var teamBtn = "FieldProven-module--teamBtn--9c1b6";