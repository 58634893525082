// extracted by mini-css-extract-plugin
export var clintCir = "Testimonials-module--clintCir--16bf3";
export var clintDots2 = "Testimonials-module--clintDots2--40c0a";
export var clintRec = "Testimonials-module--clintRec--7a34d";
export var clintsdots = "Testimonials-module--clintsdots--8910e";
export var content = "Testimonials-module--content--0342b";
export var iconContainer = "Testimonials-module--iconContainer--42705";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--5fba8";
export var imgBtn = "Testimonials-module--imgBtn--11db4";
export var person = "Testimonials-module--person--84dd6";
export var personBg = "Testimonials-module--personBg--42af5";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--f65dd";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--103e0";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--c691c";
export var row = "Testimonials-module--row--dfb06";
export var section = "Testimonials-module--section--54c8d";
export var testName = "Testimonials-module--testName--1f6a9";
export var testimonials = "Testimonials-module--testimonials--7462a";
export var testimonialsContent = "Testimonials-module--testimonialsContent--882e0";