import { graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import EngagementModels from "../components/common/Engagement-Model/EngagementModels"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import Testimonials2 from "../components/healthcare/Testimonials2"
import Banner from "../components/it-staff/Banner"
import FieldProven from "../components/it-staff/FieldProven"
import GotProject from "../components/it-staff/GotProject"
import HiringProcess from "../components/it-staff/HiringProcess"
import MakeCapital from "../components/it-staff/MakeCapital"
import Outsource from "../components/it-staff/Outsource"
import ReinventingStaff from "../components/it-staff/ReinventingStaff"
import TechnologiesUse from "../components/it-staff/TechnologiesUse"
import Testimonials from "../components/it-staff/Testimonials"
import WorkPortfolio from "../components/common/WorkPortfolio"
import MainLayout from "../layouts/MainLayout"

const StaffAugmentationCompany = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth < 1280) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth < 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const fieldProven = data?.strapiPage?.sections[1]
  const outsource = data?.strapiPage?.sections[2]
  const staff = data?.strapiPage?.sections[3]
  const process = data?.strapiPage?.sections[4]
  const makeCapital = data?.strapiPage?.sections[5]
  const engagment = data?.strapiPage?.sections[6]
  const technologies = data?.strapiPage?.sections[7]
  const faqs = data?.strapiPage?.sections[9]
  const testimonials = data?.strapiPage?.sections[8]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout bgChanged={false} schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      <FieldProven strapiData={fieldProven} />
      <Outsource strapiData={outsource} />
      <GotProject pageName="Staff Augmentation Company" />
      <ReinventingStaff strapiData={staff} />
      <HiringProcess strapiData={process} />
      <MakeCapital strapiData={makeCapital} />
      <EngagementModels strapiData={engagment} />
      <TechnologiesUse strapiData={technologies} />
      <WorkPortfolio
        portfolioHead={"Our Work Portfolio Brief"}
        portfolioDesc={
          "We couple our passion for technology with our skills, creativity, and our rich understanding of businesses and domains."
        }
      />
      {!isMobile ? (
        <Testimonials strapiData={testimonials} />
      ) : (
        <Testimonials2 strapiData={testimonials} />
      )}
      <Faqs strapiData={faqs} pageName="Staff Augmentation Company" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query mobileAppDevelopments {
    strapiPage(slug: { eq: "it-staff-augmentation" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default StaffAugmentationCompany

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/staff_augmentation_company_7031a2796c.svg"
    />
  )
}
