// extracted by mini-css-extract-plugin
export var Group = "ReinventingStaff-module--Group--dfd97";
export var Share = "ReinventingStaff-module--Share--c1a18";
export var Share2 = "ReinventingStaff-module--Share2--abc4b";
export var Staff = "ReinventingStaff-module--Staff--098f0";
export var Staff2 = "ReinventingStaff-module--Staff2--f9499";
export var Vector = "ReinventingStaff-module--Vector--3f9ad";
export var banner = "ReinventingStaff-module--banner--bd937";
export var bannerImg = "ReinventingStaff-module--bannerImg--59b21";
export var bannerUl = "ReinventingStaff-module--bannerUl--5c034";
export var cir = "ReinventingStaff-module--cir--f3b9a";
export var content = "ReinventingStaff-module--content--495b9";
export var contentDec = "ReinventingStaff-module--contentDec--38971";
export var description = "ReinventingStaff-module--description--4500f";
export var dots1 = "ReinventingStaff-module--dots1--90579";
export var dots2 = "ReinventingStaff-module--dots2--98023";
export var dots3 = "ReinventingStaff-module--dots3--1d4ab";
export var dots4 = "ReinventingStaff-module--dots4--5e743";
export var flow1 = "ReinventingStaff-module--flow1--ad11a";
export var flowdot = "ReinventingStaff-module--flowdot--31b0c";
export var heading = "ReinventingStaff-module--heading--828ab";
export var kickBtn = "ReinventingStaff-module--kickBtn--b2332";
export var liBullet = "ReinventingStaff-module--liBullet--483ae";
export var mainHeading = "ReinventingStaff-module--mainHeading--e164e";
export var staffRec = "ReinventingStaff-module--staffRec--6d679";
export var staffeCir = "ReinventingStaff-module--staffeCir--a5221";
export var subHeading = "ReinventingStaff-module--subHeading--a8da4";