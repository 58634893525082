import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import bannerImg from "../../images/it-staff/ReinventingImg.svg"
import staff1 from "../../images/it-staff/staff1.svg"
import staff2 from "../../images/it-staff/staff2.svg"
import staff3 from "../../images/it-staff/staff3.svg"
import staff4 from "../../images/it-staff/staff4.svg"
import staff5 from "../../images/it-staff/staff5.svg"
import dots2 from "../../images/it-staff/Group4.png"
import Frame from "../../images/mobile-app-developmemt/Frame.svg"
import Group2 from "../../images/it-staff/Group2.png"
import staffCir from "../../images/it-staff/staffCir.svg"
import flow1 from "../../images/it-staff/flow1.svg"
import cir from "../../images/custom-web-application/cir.svg"
import flowDot from "../../images/it-staff/flowDot.svg"
import * as styles from "./ReinventingStaff.module.scss"

const ReinventingStaff = ({ strapiData }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={styles.banner}>
      <div className={styles.flow1}>
        <img src={flow1} decoding="async" loading="lazy" alt="flow" />
      </div>
      <div className={styles.cir}>
        <img decoding="async" loading="lazy" src={cir} alt="circle" />
      </div>
      <div className={styles.dots1}>
        <img decoding="async" loading="lazy" src={dots2} alt="dots" />
      </div>
      <div className={styles.dots3}>
        <img decoding="async" loading="lazy" src={Frame} alt="frame" />
      </div>
      <div className={styles.Group}>
        <img decoding="async" loading="lazy" src={Group2} alt="background" />
      </div>
      <div className={styles.flowdot}>
        <img src={flowDot} alt="dots" decoding="async" loading="lazy" />
      </div>
      <Container>
        <Row className="align-items-center gap-30 ">
          <div>
            <div className={styles.heading}>
              <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
            </div>
            <div className={styles.Share}>
              <Row>
                <Col lg={{ span: 4, offset: 2 }}>
                  <div className={styles.content}>
                    <img
                      className={styles.Staff2}
                      src={staff1}
                      decoding="async"
                      loading="lazy"
                      alt="staff"
                    />
                    <h3>Share CVs</h3>
                    <p>
                      Resumes of talented developers will drop in your inbox
                      within 48 hours. You can select the ones who can best
                      serve the needs of your project and business.
                    </p>
                  </div>
                </Col>
                <Col lg={{ span: 4, offset: 0 }}>
                  <div className={styles.content}>
                    <img
                      className={styles.Staff2}
                      src={staff2}
                      decoding="async"
                      loading="lazy"
                      alt="staff"
                    />
                    <h3>Start A Trial</h3>
                    <p>
                      Once you have hand-picked excellent developers, things
                      will move forward with 1 week trial.
                    </p>
                  </div>
                </Col>
              </Row>
              <div lg={5} md={12} className="text-center mt-5 mt-md-0">
                {image ? (
                  <GatsbyImage
                    placeholder="blurred"
                    decoding="async"
                    loading="lazy"
                    image={image}
                    alt={strapiData?.title}
                  />
                ) : (
                  <img
                    src={bannerImg}
                    alt="banner"
                    decoding="async"
                    loading="lazy"
                    className={styles.Staff}
                  />
                )}
              </div>
              <div className={styles.dots4}>
                <img decoding="async" loading="lazy" src={dots2} alt="dots" />
              </div>
              <Row>
                <Col lg={{ span: 4, offset: 0 }}>
                  <div className={styles.content}>
                    <img
                      className={styles.Staff2}
                      src={staff3}
                      decoding="async"
                      loading="lazy"
                      alt="staff"
                    />
                    <h3>Send Us A Query</h3>
                    <p>
                      Share your ideas and inquiries concerning tech stack,
                      working hours, team structure, reporting and procedures
                      with us. We ensure 100% confidentiality.
                    </p>
                  </div>
                </Col>
                <Col lg={{ span: 4, offset: 0 }}>
                  <div className={styles.content}>
                    <img
                      className={styles.Staff2}
                      src={staff4}
                      decoding="async"
                      loading="lazy"
                      alt="staff"
                    />
                    <h3>Conduct Interviews</h3>
                    <p>
                      Interview the shortlisted candidates over a video call and
                      pick the ones that impressed you.
                    </p>
                  </div>
                </Col>
                <Col lg={{ span: 4, offset: 0 }}>
                  <div className={styles.content}>
                    <img
                      className={styles.Staff2}
                      src={staff5}
                      decoding="async"
                      loading="lazy"
                      alt="staff"
                    />
                    <h3>Not Satisfied</h3>
                    <p>
                      If you are dissatisfied with the resource, you may always
                      select another one.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <div className={styles.Share2}>
              <Col lg={6} md={12}>
                <div className={styles.content}>
                  <img
                    className={styles.Staff2}
                    src={staff1}
                    decoding="async"
                    loading="lazy"
                    alt="staff"
                  />
                  <p>Share CVs</p>
                  <p className={styles.contentDec}>
                    Resumes of talented developers will drop in your inbox
                    within 48 hours. You can select the ones who can best serve
                    the needs of your project and business.
                  </p>
                </div>
              </Col>
              <Col md={12}>
                <div className={styles.content}>
                  <img
                    className={styles.Staff2}
                    src={staff2}
                    decoding="async"
                    loading="lazy"
                    alt="staff"
                  />
                  <p>Start A Trial</p>
                  <p className={styles.contentDec}>
                    Once you have hand-picked excellent developers, things will
                    move forward with 1week trial.
                  </p>
                </div>
              </Col>
              <Col md={12}>
                <div className={styles.content}>
                  <img
                    className={styles.Staff2}
                    src={staff3}
                    decoding="async"
                    loading="lazy"
                    alt="staff"
                  />
                  <p>Send Us A Query</p>
                  <p className={styles.contentDec}>
                    Share your ideas and inquiries concerning tech stack,
                    working hours, team structure, reporting and procedures with
                    us. We ensure 100% confidentiality.
                  </p>
                </div>
              </Col>
              <Col md={12}>
                <div className={styles.content}>
                  <img
                    className={styles.Staff2}
                    src={staff4}
                    decoding="async"
                    loading="lazy"
                    alt="staff"
                  />
                  <p>Conduct Interviews</p>
                  <p className={styles.contentDec}>
                    Interview the shortlisted candidates over a video call and
                    pick the ones that impressed you.
                  </p>
                </div>
              </Col>
              <Col md={12}>
                <div className={styles.content}>
                  <img
                    className={styles.Staff2}
                    src={staff5}
                    decoding="async"
                    loading="lazy"
                    alt="staff"
                  />
                  <p>Not Satisfied</p>
                  <p className={styles.contentDec}>
                    If you are dissatisfied with the resource, you may always
                    select another one.
                  </p>
                </div>
              </Col>
            </div>
            <div className={styles.staffeCir}>
              <img
                src={staffCir}
                decoding="async"
                loading="lazy"
                alt="circle"
              />
            </div>
            <div className={styles.kickBtn}>
              {strapiData?.buttons[0] && (
                <Link
                  to={strapiData?.buttons[0]?.url}
                  className="btn_black_border_banner"
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default ReinventingStaff
