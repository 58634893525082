// extracted by mini-css-extract-plugin
export var Group = "MakeCapital-module--Group--f2dd6";
export var arrowImg = "MakeCapital-module--arrowImg--2bfc1";
export var bgImg = "MakeCapital-module--bgImg--a3c42";
export var card = "MakeCapital-module--card--415b3";
export var cardWrapper = "MakeCapital-module--cardWrapper--c4ee4";
export var choseImg = "MakeCapital-module--choseImg--3ca61";
export var cir = "MakeCapital-module--cir--50aff";
export var description = "MakeCapital-module--description--06d43";
export var dots1 = "MakeCapital-module--dots1--8f010";
export var dots2 = "MakeCapital-module--dots2--96f3e";
export var dots4 = "MakeCapital-module--dots4--2a5bb";
export var flowdot = "MakeCapital-module--flowdot--4ca09";
export var for1 = "MakeCapital-module--for1--57a81";
export var heading = "MakeCapital-module--heading--9994e";
export var hireBtn = "MakeCapital-module--hireBtn--e2f6d";
export var reactangle = "MakeCapital-module--reactangle--fe5bd";
export var renrec = "MakeCapital-module--renrec--428d6";
export var staffcir = "MakeCapital-module--staffcir--2a007";
export var subservice = "MakeCapital-module--subservice--b7dcd";
export var teamBtn = "MakeCapital-module--teamBtn--befb2";